// sass-lint:disable-all
.page-wrapper,
.page-wrapper .columns {
	display: block;
}

.page-wrapper {
	min-height: 100%;
}

//  Magento/blank
.page-print {
	.logo {
		display: block;
		float: none;
		text-align: left;
	}
}

@media print {
	* {
		-webkit-filter: none !important; // Use in 41 Chrome
		background: transparent !important;
		color: #000 !important;
		filter: none !important;
		text-shadow: none !important;
	}

	.alert-bar {
		display: none;
	}

	//  Black prints faster:h5bp.com/s
	a,
	a:visited {
		text-decoration: underline !important;
	}

	//  Don't show links for images, or javascript/internal links
	pre,
	blockquote {
		border: 1px solid #000;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group;
	}

	.table-wrapper table {
		width: 100%;

		td {
			width: auto;
		}

		table-layout: fixed;

		& > tfoot > tr:first-child {
			border-top: 1px solid #000;
		}
	}

	.box,
	tr,
	img {
		page-break-inside: avoid;
	}

	img {
		max-width: 100% !important;
	}

	@page {
		margin: 1cm;
	}

	.block-content,
	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	.block-content {
		page-break-before: avoid;
	}

	.block-title,
	h2,
	h3 {
		page-break-after: avoid;
	}

	.nav-toggle {
		display: none !important;
	}

	.sidebar,
	.nav-sections,
	.header.content > *[class]:not(.logo-wrapper),
	.panel.wrapper > *[class],
	.footer.content > *[class] {
		display: none;
	}

	.logo,
	.footer .copyright {
		display: block !important;
		margin: 10px 0;
	}

	.order-details-items {
		.order-items {
			.order-gift-message {
				&:not(.expanded-content) {
					height: auto;
					visibility: visible;
				}
			}
		}
	}

	.column.main {
		float: none !important;
		width: 100% !important;
	}

	.breadcrumbs {
		margin: 0 auto;
		text-align: right;
	}

	.footer.content {
		padding: 0;
	}

	.hidden-print {
		display: none !important;
	}

	.logo-wrapper {
		display: block;
	}

	.header.content {
		padding-bottom: 10px;
	}

	.account {
		.column.main .block.block-order-details-view .block-content:not(.widget):after {
			clear: both;
			content: '';
			display: table;
		}

		.columns .column.main {
			margin-bottom: 0;
		}
	}
}
